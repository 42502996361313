import React, { Dispatch, useCallback, useEffect, useState } from "react"
import { Action, useStoreContext } from "../../../../Store";
import { IADState } from "../../../../IntegrationApps/IntegrationAppDetails/state";
import { useIdentity } from "../../../../IdentityProvider";
import { Destination, useDestination, useDestinationForm, useGetDestinations, useGetModelsForRecipient } from "@prequel/react";
import { AUTH_TOKEN_URL, ORG_ID, PREQUEL_API_HOST, T9_PERMISSION_MAPPINGS, VENDOR_TO_PREQUEL_VENDOR_MAPPING, ZDQ_VENDOR_CONNECTOR_TYPES } from "../Constants";
import Connect from "../../../../Connect/Connect";
import DestinationDetails from "./DestinationDetails";
import { Card, Spinner, Typography, designTokens } from "@platform-ui/design-system";
import { IdentityPermissions } from "../../../../IdentityProvider/constants/permissions";
import { ZDQ_DESTINATION_DATA, ZDQ_RECIPIENT } from "../action_types";
import ConnectorAuthentication from "./ConnectorAuthentication";
import _ from 'lodash';
import fetchAuthToken from "../fetchTokens/fetchAuthToken";

interface Recipient {
    id: string;
    name: string;
    schema: string;
    id_in_provider_system: string;
    products: string[];
    is_deleted: boolean;
    created_at: string;
    updated_at: string;
}

interface BillingModelMetaData {
    objectName: string;
    id: number
}

function ZdqConnectorSetup({ vendor }: { vendor: ZDQ_VENDOR_CONNECTOR_TYPES }) {

    // Contexts
    const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };

    // T9 permissions
    const { hasPermission } = useIdentity();
    const hasPermissionsEnabled = hasPermission(`permission.${T9_PERMISSION_MAPPINGS[vendor]}` as IdentityPermissions)

    // hooks
    const [destination, setDestination] = useDestination();
    const destinationForm = useDestinationForm(destination, ORG_ID, { host: PREQUEL_API_HOST, includeInternalFields: false });

    // States
    const [showSpinner, setShowSpinner] = useState(false);
    // const [models, setModels] = useState([]);
    const [models, setModels] = useState([]);

    const destinationData = state.settingsHash[ZDQ_DESTINATION_DATA];
    const recipient = state.settingsHash[ZDQ_RECIPIENT];

    // Vars
    const fetchTokenUrl = state.settingsUrl.replace('.json', AUTH_TOKEN_URL);

    const setDestinationField = useCallback(
        (
            key: keyof Destination,
            value: string | string[] | boolean | undefined
        ) => {
            setDestination((currentDestination) => ({
                ...currentDestination,
                [key]: value,
            }));
        },
        [setDestination]
    );

    async function getRecipient() {
        try {
            setShowSpinner(true);
            const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
            const options = {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': csrf
                })
            };
            const response = await window.fetch(`${state.settingsUrl.replace('.json', '/zdq/recipient')}`, options);
            const jsonResponse: { recipient: Recipient, messsage?: string, error?: string } = await response.json();
            if (!response.ok) {
                throw Error(jsonResponse.messsage || 'Error while fetching the recipient for the tenant!');
            }
            if (jsonResponse?.recipient) {
                dispatch({ type: ZDQ_RECIPIENT, payload: jsonResponse?.recipient ? jsonResponse?.recipient : {} })
                return jsonResponse?.recipient;
            }
            Connect.log('No relevant recipients found for the tenant', response);
        } catch (error) {
            Connect.log(error);
            dispatch({ type: ZDQ_RECIPIENT, payload: {} })
        } finally {
            setShowSpinner(false);
        }
    }

    useEffect(() => {
        if (hasPermissionsEnabled) {
            getRecipient();
        }
    }, [hasPermissionsEnabled]);

    const fetchAuthTokenWrapper = useCallback(async () => {
        return await fetchAuthToken(fetchTokenUrl);
    }, []);

    const getDestinations = useGetDestinations(fetchAuthTokenWrapper, window.location.host, PREQUEL_API_HOST);

    async function getDestinationDetails() {
        try {
            setShowSpinner(true);
            const response = await getDestinations();
            if (!response) {
                throw Error('Error while fetching destination for the tenant');
            }

            if (response.length === 0) {
                throw Error('No destinations found for the tenant. Empty list');
            }
            const existDest = response?.filter((dest) => dest?.id_in_provider_system === recipient?.id_in_provider_system && dest.vendor === VENDOR_TO_PREQUEL_VENDOR_MAPPING[vendor] && dest.name === `ZDQ_${recipient?.id_in_provider_system}_${vendor.toUpperCase()}_CONNECTOR`)[0] || undefined
            dispatch({ type: ZDQ_DESTINATION_DATA, payload: existDest ? existDest : {} })
        } catch (error) {
            dispatch({ type: ZDQ_DESTINATION_DATA, payload: {} })
            Connect.log(error);
        } finally {
            setShowSpinner(false);
        }
    }

    const getModels = useGetModelsForRecipient(fetchAuthTokenWrapper, window.location.host, PREQUEL_API_HOST);

    async function fetchModels() {
        try {
            setShowSpinner(true);
            const response = await getModels();
            if (!response) {
                throw Error('Error while fetching models for the tenant');
            }
            if (response.length === 0) {
                throw Error('No models found for the tenant. Empty list');
            }
            const m = response?.sort().map((model, index) => {
                return {
                    objectName: model.model_name,
                    id: index + 1
                } as BillingModelMetaData
            });
            setModels(m);
        } catch (error) {
            Connect.log(error);
        } finally {
            setShowSpinner(false);
        }
    }

    useEffect(() => {
        if (hasPermissionsEnabled && recipient) {
            getDestinationDetails();
            fetchModels();
        }
    }, [hasPermissionsEnabled, recipient]);



    useEffect(() => {
        setDestinationField('vendor', VENDOR_TO_PREQUEL_VENDOR_MAPPING[vendor]);
        setDestinationField('recipient_id', recipient?.id);
        setDestination((currentDestination) => ({
            ...currentDestination,
            name: `ZDQ_${recipient?.id_in_provider_system}_${vendor.toUpperCase()}_CONNECTOR`,
            id_in_provider_system: recipient?.id_in_provider_system,
            products: recipient?.products
        }));
    }, [recipient])

    if (!hasPermissionsEnabled) {
        return <Card autoDistance id="disabled-tenant-card" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="You don't have permissions enabled to view this tab. Please contact Zuora support if you are seeing this message." />}
        />
    }

    if (showSpinner) {
        return <Spinner e2e="connector-auth-setup-spinner" size="large"></Spinner>
    }

    if (!recipient) {
        return <Card autoDistance id="connector-setup-no-recipient-or-models" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="Error fetching the recipient details for this tenant. Please contact Zuora support if you are seeing this message." />
        }
        />
    }

    // This encapsulates the case when a destination exists for the tenant, however the models don't
    if (!_.isEmpty(destinationData) && !models) {
        return <Card autoDistance id="connector-setup-no-recipient-or-models" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="Error fetching the models for this tenant. Please contact Zuora support if you are seeing this message." />
        }
        />
    }

    if (!_.isEmpty(destinationData) && recipient && models && destinationForm) {
        return <DestinationDetails vendor={vendor} destinationData={destinationData} destinationForm={destinationForm} models={models}></DestinationDetails>
    }

    return <ConnectorAuthentication vendor={vendor} models={models} destination={destination} destinationForm={destinationForm} setDestinationField={setDestinationField}></ConnectorAuthentication>
}

export default ZdqConnectorSetup 