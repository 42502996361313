
const resolvePrequelHostEnv = () => {
  const host = window.location.host;

  if (host.includes("localhost") || host.includes("staging")) {
    return 'staging';
  }

  if (host.includes("sandbox.eu.zuora") || host.includes("test.eu.zuora")) {
    return 'eu_sandbox'
  }

  //   US-SBX/NA-SBX/CSBX (All 3 served by prequel's US SBX deployment)
  //   NOTE: The below statement handles the 3 deployment - US-SBX/NA-SBX/US-CSBX
  if (host.includes("apisandbox.zuora") || host.includes("test.zuora") || host.includes("sandbox.na.zuora")) {
    return 'sandbox'
  }

  //   ---------- PROD ----------

  // TDOO: Return the EU PROD prequel deployment
  if (host.includes("eu.zuora")) {
    return 'eu_production'
  }

  //   TDOO: Return the NA PROD and US PROD prequel deployment
  if (host.includes("zuora")) {
    return 'production'
  }

  return 'staging'
}

const PREQUEL_API_HOST_ENV = resolvePrequelHostEnv();

const ENV_TO_PREQUEL_API_HOST_MAPPING = {
  'staging': 'https://prequel-stg-na.zuora.com',
  'eu_sandbox': 'https://prequel-sbx-eu.zuora.com',
  'sandbox': 'https://prequel-sbx-na.zuora.com',
  'eu_production': 'https://prequel-eu.zuora.com',
  'production': 'https://prequel-na.zuora.com',
};

export const PREQUEL_API_HOST = ENV_TO_PREQUEL_API_HOST_MAPPING[PREQUEL_API_HOST_ENV]

// Api urls
export const AUTH_TOKEN_URL = '/zdq/token'

const PREQUEL_ORG_ID_TO_HOST_MAPPING = {
  'staging': '30f0d1f0-04cb-4aa4-a35a-d757c336f90e',
  'eu_sandbox': 'b95c991b-5c9d-4833-a8db-4f2f58050146',
  'sandbox': '2f48afa1-7f73-4ab7-9360-27f4a51b6134',
  'eu_production': 'ac301251-bd97-4bfc-981e-dee14bd9414a',
  'production': 'f2f155f9-6b66-4ac5-b8a7-5a34a6329884',
}

export const ORG_ID = PREQUEL_ORG_ID_TO_HOST_MAPPING[PREQUEL_API_HOST_ENV];


export declare type ZDQ_VENDOR_CONNECTOR_TYPES =
  "gcp_postgres" |
  "aws_aurora_postgres" |
  "aws_rds_postgres" |
  "generic_postgres" |
  "aurora_mysql" |
  "generic_mysql" |
  "aws_redshift" |
  "bigquery" |
  "aws_s3" |
  "abs" |
  "aws_athena" |
  "gcs" |
  "databricks" |
  "sql_server"

// Vendors
export const GCP_POSTGRES: ZDQ_VENDOR_CONNECTOR_TYPES = 'gcp_postgres';
export const AWS_AURORA_POSTGRES: ZDQ_VENDOR_CONNECTOR_TYPES = 'aws_aurora_postgres';
export const AWS_RDS_POSTGRES: ZDQ_VENDOR_CONNECTOR_TYPES = "aws_rds_postgres";
export const GENERIC_POSTGRES: ZDQ_VENDOR_CONNECTOR_TYPES = 'generic_postgres';
export const AURORA_MYSQL: ZDQ_VENDOR_CONNECTOR_TYPES = 'aurora_mysql';
export const GENERIC_MYSQL: ZDQ_VENDOR_CONNECTOR_TYPES = 'generic_mysql';
export const AWS_REDSHIFT: ZDQ_VENDOR_CONNECTOR_TYPES = 'aws_redshift';
export const BIGQUERY: ZDQ_VENDOR_CONNECTOR_TYPES = 'bigquery';
export const AWS_S3: ZDQ_VENDOR_CONNECTOR_TYPES = 'aws_s3';
export const AZURE_BLOB_STORAGE: ZDQ_VENDOR_CONNECTOR_TYPES = "abs";
export const AWS_ATHENA: ZDQ_VENDOR_CONNECTOR_TYPES = "aws_athena";
export const GOOGLE_CLOUD_STORAGE: ZDQ_VENDOR_CONNECTOR_TYPES = "gcs";
export const DATABRICKS: ZDQ_VENDOR_CONNECTOR_TYPES = "databricks";
export const SQL_SERVER: ZDQ_VENDOR_CONNECTOR_TYPES = "sql_server";

// vendor to title mappings since some title might have a different formatting
export const VENDOR_TO_CONNECTOR_TITLE_MAPPING: Record<ZDQ_VENDOR_CONNECTOR_TYPES, string> = {
  gcp_postgres: "GCP Postgres",
  aws_aurora_postgres: "AWS Aurora Postgres",
  aws_rds_postgres: "AWS RDS Postgres",
  generic_postgres: "Generic Postgres",
  aurora_mysql: "Aurora MySql",
  generic_mysql: "Generic MySql",
  aws_redshift: "AWS Redshift",
  bigquery: "BigQuery",
  aws_s3: "AWS S3",
  abs: "Azure Blob Storage",
  aws_athena: "AWS Athena",
  gcs: "Google Cloud Storage",
  databricks: "Databricks",
  sql_server: "Sql Server"

}

export const VENDOR_TO_PREQUEL_VENDOR_MAPPING: Record<ZDQ_VENDOR_CONNECTOR_TYPES, string> = {
  gcp_postgres: "postgres",
  aws_aurora_postgres: "aurora_postgres",
  aws_rds_postgres: "postgres",
  generic_postgres: "postgres",
  aurora_mysql: "aurora_mysql",
  generic_mysql: "mysql",
  aws_redshift: "redshift",
  bigquery: "bigquery",
  aws_s3: "s3",
  abs: "abs",
  aws_athena: "athena",
  gcs: "gcs",
  databricks: "databricks",
  sql_server: "sql_server"
}

// T9 permissions mappings
export const T9_PERMISSION_MAPPINGS: Record<ZDQ_VENDOR_CONNECTOR_TYPES, string> = {
  gcp_postgres: "ZuoraGCPPostgresConnector",
  aws_aurora_postgres: "ZuoraAWSAuroraConnector",
  aws_rds_postgres: "ZuoraAWSRDSConnector",
  generic_postgres: "ZuoraGenericPostgresConnector",
  aurora_mysql: "ZuoraAuroraMySQLConnector",
  generic_mysql: "ZuoraGenericMySQLConnector",
  aws_redshift: "ZuoraAWSRedshiftConnector",
  bigquery: "ZuoraBigQueryConnector",
  aws_s3: "ZuoraAWSS3Connector",
  abs: "ZuoraAzureBlobStorageConnector",
  aws_athena: "ZuoraAWSAthenaConnector",
  gcs: "ZuoraGoogleCloudStorageConnector",
  databricks: "ZuoraDatabricksConnector",
  sql_server: "ZuoraSQLServerConnector"
}

// Vendor Mapping for links to KC Docs
export const VENDOR_TO_KC_LINK_MAPPINGS: Record<ZDQ_VENDOR_CONNECTOR_TYPES, string> = {
  gcp_postgres: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_GCP_Postgres",
  aws_aurora_postgres: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_AWS_Aurora_Postgres",
  aws_rds_postgres: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_AWS_RDS_Postgres",
  generic_postgres: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_Generic_Postgres",
  aurora_mysql: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_Aurora_MySQL",
  generic_mysql: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_Generic_MySQL",
  aws_redshift: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_AWS_Redshift",
  bigquery: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_BigQuery",
  aws_s3: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_AWS_S3",
  abs: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_Azure_Blob_Storage",
  aws_athena: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_AWS_Athena",
  gcs: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_Google_Cloud_Storage",
  databricks: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_Databricks",
  sql_server: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_SQL_Server",
}

// Interfaces
export interface BillingModelMetaData {
  objectName: string;
  id: number
}